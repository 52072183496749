<template>
  <section id="about" class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <Images :fpath="path" style="width: 100%" />
        </div>
        <div class="col-md-5 saintheading">
          <div class="col-md-12 pb-2">
            <a href="../vatican-news" class="cta-link">Back to news</a>
            <h2 class="pt-4">Saint Rita of Cascia</h2>
            <p>Saint of the Day for May 22</p>
            <p>(1381 – May 22, 1457)</p>
            <h6>Share Post</h6>
          </div>
          <div class="col-lg-4 col-md-12 socialfooter">
            <div class="social-links">
              <a href="" target="blank" class="twitter">
                <ICON_Twitter :size="icon_size" :color="colors" />
              </a>
              <a href="" target="blank" class="facebook">
                <ICON_Facebook :size="icon_size" :color="colors" />
              </a>
              <a href="" target="blank" class="instagram">
                <ICON_Instagram :size="icon_size" :color="colors" />
              </a>
              <!-- <a href="#" target="blank"> <ICON_telegram :size="icon_size"/></a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7 aboutsection">
          <div class="section-title mt-2">
            <p>
              Like Elizabeth Ann Seton, Rita of Cascia was a wife, mother,
              widow, and member of a religious community. Her holiness was
              reflected in each phase of her life.
            </p>
            <p>
              Born at Roccaporena in central Italy, Rita wanted to become a nun
              but was pressured at a young age into marrying a harsh and cruel
              man. During her 18-year marriage, she bore and raised two sons.
              After her husband was killed in a brawl and her sons had died,
              Rita tried to join the Augustinian nuns in Cascia. Unsuccessful at
              first because she was a widow, Rita eventually succeeded.
            </p>
            <p>
              Over the years, her austerity, prayerfulness, and charity became
              legendary. When she developed wounds on her forehead, people
              quickly associated them with the wounds from Christ’s crown of
              thorns. She meditated frequently on Christ’s passion. Her care for
              the sick nuns was especially loving. She also counseled lay people
              who came to her monastery.
            </p>
            <p>
              Beatified in 1626, Rita was not canonized until 1900. She has
              acquired the reputation, together with Saint Jude, as a saint of
              impossible cases. Many people visit her tomb each year.
            </p>
          </div>
        </div>
        <ContentByFilter />
      </div>
    </div>
  </section>
</template>

<script>
import Images from "@/components/Image.vue";
import ContentByFilter from "@/components/ContentByFilter.vue";
import ICON_Facebook from "@/assets/icons/Facebook.vue";
import ICON_Twitter from "@/assets/icons/Twitter.vue";
import ICON_Instagram from "@/assets/icons/Instagram.vue";
export default {
  components: {
    ContentByFilter,
    Images,
    ICON_Facebook,
    ICON_Twitter,
    ICON_Instagram,
  },
  data() {
    return {
      path: "saint-rita-of-cascia.jpg",
      colors: "black",
      icon_size: 24,
    };
  },
};
</script>

<style scoped>
.cta-link {
  border-radius: 30px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #0880e8;
  color: #0880e8;
}
.cta-link:hover {
  color: #fff;
  background-color: #0880e8;
}
.socialfooter {
  padding: 0px 0px 10px 0px !important;
}
.social-links a {
  margin-right: 20px;
}
.social-links {
  display: flex;
}
.saintheading {
  margin: 0 auto;
  padding: 50px;
}
select.selectpicker {
  padding: 5px 10px;
  font-size: 16px;
}
.about {
  padding: 110px 30px 30px 30px;
  background: #f9f9f9;
  position: relative;
}
.aboutsection {
  padding: 30px;
}
.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .container {
  position: relative;
}

.about .content h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
}

@media (max-width: 992px) {
  .about {
    padding: 30px;
  }
}
</style>
