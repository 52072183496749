<template>
  <Header />
  <SaintRitaOfCascia />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SaintRitaOfCascia from "@/components/vatican-news/SaintRitaOfCascia.vue";

export default {
  components: {
    Header,
    Footer,
    SaintRitaOfCascia,
  },
};
</script>
