<template>
  <img alt="" :src="getImgUrl()" />
</template>

<script>
export default {
  props: {
    fpath: String,
    width: String,
  },
  methods: {
    getImgUrl() {
      return require(`@/assets/images/${this.fpath}`);
    },
  },
};
</script>
