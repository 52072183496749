<template>
  <div class="col-md-5 aboutsection">
    <div class="row" style="text-align: center">
      <div v-for="i in lang" :key="i.code" class="col-lg-3 col-md-6 col-6">
        <a-button
          :class="['lnklang', i.code == sel_lang ? 'active' : '']"
          @click="HandleLangClick(i.code)"
          >{{ i.name }}</a-button
        >
      </div>
      <h5 class="pt-2" style="text-align: right">Find by Date</h5>

      <!-- <div
        :style="{
          width: '300px',
          border: '1px solid #d9d9d9',
          borderRadius: '4px',
        }"
      > -->
      <div>
        <a-calendar
          v-model:value="value"
          :fullscreen="false"
          @change="onChange"
          style="text-align: right"
        />
      </div>

      <!-- <a-calendar
        v-model:value="value"
        @panelChange="onPanelChange"
        style="text-align: right"
      /> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    sel_lang: {
      type: String,
      default: "en",
    },
    sel_date: {
      type: String,
      required: true,
    },
  },
  emits: ["update:lang", "update:date"],
  methods: {
    HandleLangClick(code) {
      if (code == this.sel_lang) {
        return;
      }
      this.$emit("update:lang", code);
    },
    // onSelect(value) { @select="onSelect"
    //   if (value.isAfter()) {
    //     return;
    //   }
    //   this.$emit("update:date", value.format("YYYY-MM-DD"));
    // },
    onChange(value) {
      if (value.isAfter()) {
        return;
      }
      this.$emit("update:date", value.format("YYYY-MM-DD"));
    },
  },
  setup() {
    const value = ref();
    const lang = [
      {
        name: "English",
        code: "en",
      },
      {
        name: "Tamil",
        code: "ta",
      },
      {
        name: "Spanish",
        code: "es",
      },
      {
        name: "Swahili",
        code: "sw",
      },
    ];

    return {
      value,
      lang,
    };
  },
});
</script>

<style scoped>
/* select.selectpicker {
  padding: 5px 10px;
  font-size: 16px;
} */
button.lnklangs:hover {
  color: #0880e8;
  border-color: #0880e8;
}
.ant-btn.lnklang.active {
  color: #0880e8;
  border-color: #0880e8;
}
button.lnklang:hover {
  color: #0880e8;
  border-color: #0880e8;
}
button.lnklangs {
  margin: 10px;
  font-size: 14px;
  padding: 5px 20px;
  color: #000;
  background-color: #fff;
  border-color: #000;
}
button.lnklang {
  margin: 10px;
  font-size: 14px;
  padding: 5px 15px;
  color: #000;
  background-color: #fff;
  border-color: #000;
}
.about {
  padding: 30px;
  background: #f9f9f9;
  position: relative;
}
.aboutsection {
  padding: 30px;
}
.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .container {
  position: relative;
}

.about .content h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
}

@media (max-width: 992px) {
  .about {
    padding: 30px;
  }
}
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>
